import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { NavLink } from "../components/nav"
import { StaticImage } from "gatsby-plugin-image"
import "../css/facilities.css"

export default function FacilitiesPage(props) {
  return (
    <Layout>
      <Seo title="Facilities" />
      <div className="contentBox">
        <h1 className="facilitiesHeader">Facilities</h1>
        <div className="facilities-container">
          <div className="facilities-list-container">
            <h2 className="facilities-city-header">Bakersfield</h2>
            <ul className="facilities-list">
              <NavLink name="independence" to="/independence" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/independence/independence-front1.jpg"
                  alt="front of independence facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Independence at Centennial Grove
                  </div>
                  <div className="facilities-text-secondary">
                    Level 4I Specialized Adult Residential Facility
                  </div>
                </div>
              </NavLink>
              <NavLink name="liberty" to="/liberty" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/liberty/liberty-front1.jpg"
                  alt="front of liberty facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Liberty in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Level 4I Specialized Adult Residential Facility
                  </div>
                </div>
              </NavLink>
              <NavLink name="endeavor" to="/endeavor" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/endeavor/endeavor-front1.jpg"
                  alt="front of endeavor facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Endeavor in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Level 4I Specialized Adult Residential Facility
                  </div>
                </div>
              </NavLink>
              <NavLink name="united" to="/united" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/united/united-front1.jpg"
                  alt="front of united facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    United in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Level 4I Adult Residential Facility
                  </div>
                </div>
              </NavLink>
              <NavLink name="triumph" to="/triumph" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/triumph/triumph-front1.jpg"
                  alt="front of triumph facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Triumph in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Specialized Enhanced Behavioral Supports Home
                  </div>
                </div>
              </NavLink>
            </ul>
          </div>
          <div className="facilities-list-container">
            <h2 className="facilities-city-header">Visalia</h2>
            <ul className="facilities-list">
              <NavLink name="discovery" to="/discovery" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/discovery/discovery-front1.jpg"
                  alt="front of discovery facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Discovery in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Specialized Enhanced Behavioral Supports Home
                  </div>
                </div>
              </NavLink>
              <NavLink name="frontier" to="/frontier" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/frontier/frontier-front3.jpg"
                  alt="front of frontier facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-pirmary">
                    Frontier in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Specialized Enhanced Behavioral Supports Home
                  </div>
                </div>
              </NavLink>
              <NavLink name="honor" to="/honor" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/honor/honor-front1.jpg"
                  alt="front of honor facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Honor in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Enhanced Behavioral Supports Home
                  </div>
                </div>
              </NavLink>
              <NavLink name="patriot" to="/patriot" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/patriot/patriot-front1.jpg"
                  alt="front of patriot facility"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Patriot in the West
                  </div>
                  <div className="facilities-text-secondary">
                    Specialized Adult Residential Facility (Transition from ARF
                    to RCFE)
                  </div>
                </div>
              </NavLink>
              <NavLink name="withone" to="/withone" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/facilities/withone/withone-front1.jpg"
                  alt="front of with one program"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">With one West</div>
                  <div className="facilities-text-secondary">
                    Specialized Adult Day Program
                  </div>
                </div>
              </NavLink>
              <NavLink name="valor" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/coming_soon.jpg"
                  alt="coming soon image"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Valor in the West
                  </div>
                </div>
              </NavLink>
            </ul>
          </div>
          <div className="facilities-list-container">
            <h2 className="facilities-city-header">Tulare</h2>
            <ul className="facilities-list">
              <NavLink name="anthem" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/coming_soon.jpg"
                  alt="coming soon image"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Anthem in the West
                  </div>
                </div>
              </NavLink>
              <NavLink name="bravery" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/coming_soon.jpg"
                  alt="coming soon image"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Bravery in the West
                  </div>
                </div>
              </NavLink>
              <NavLink name="courage" isActive={false}>
                <StaticImage
                  className="facilities-list-image"
                  src="../images/coming_soon.jpg"
                  alt="coming soon image"
                  placeholder="blurred"
                  width={400}
                  height={300}
                />
                <div className="facilities-text-container">
                  <div className="facilities-text-primary">
                    Courage in the West
                  </div>
                </div>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
